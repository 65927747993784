<template>
    <div class="pdt-info" style="width: 100%;">
      <el-card shadow="never">
        <template #header>
          <div class="card-header" style="text-align: left">
            <el-col :span="4" class="hidden-md-and-down">
              <span>编辑产品信息</span>
            </el-col>
            <!--
                      <el-col :xs="10" :sm="6" :md="4" :lg="3">
                      <div class="option">
                          <el-button type="primary" @click="submitform" :disabled="btndisabled" :icon="[btndisabled ? 'el-icon-loading':'']">{{btndisabled?"提交中":"保存"}}</el-button>
                          <el-button @click="goback">返回</el-button>
                      </div>
                      </el-col>-->
          </div>
        </template>
        <el-scrollbar height="700px">

        <div class="content">
          <el-form
            :model="pdtinfo"
            :rules="rules"
            ref="pdtinfo"
            label-width="120px"
            class="pdtform"
          >
            <el-form-item
              label="选择商户"
              prop="merchantid"
              v-if="ismerchant != 1"
            >
              <el-select
                v-model="pdtinfo.merchantid"
                clearable
                filterable
                placeholder="请选择商户"
                @change="GetTemp"
                :disabled="pdtinfo.ID ? true : false"
              >
                <el-option
                  v-for="merchant in merchants"
                  :key="merchant.ID"
                  :label="merchant.Merchant"
                  :value="merchant.ID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择品类" prop="category">
              <!--<el-select v-model="pdtinfo.category" placeholder="选择品类" filterable style="width:250px" clearable @change="AlterModelList">
                              <el-option
                                  v-for="category in categorys"
                                  :key="category.ID"
                                  :label="category.Category"
                                  :value="category.ID"
                              >
                              <span class="sel_cate_name" :class="'depth_' + category.Depth">{{
                                  category.Category
                              }}</span>
                              </el-option>
                          </el-select>-->
              <el-cascader
                :options="option"
                v-model="pdtinfo.category"
                placeholder="选择品类"
                clearable
                :show-all-levels="false"
                :props="props"
                @change="ResetInfo"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="选择品牌" prop="brand">
              <el-select
                v-model="pdtinfo.brand"
                clearable
                placeholder="请选择品牌"
                filterable
                @change="SetModel"
                :disabled="pdtinfo.category ? false : true"
              >
                <el-option
                  v-for="brand in brands"
                  :key="brand.ID"
                  :label="brand.Brand"
                  :value="brand.ID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择型号" prop="model">
              <el-select
                v-model="pdtinfo.model"
                clearable
                placeholder="请选择型号"
                filterable
                @visible-change="GetModel"
                :disabled="pdtinfo.brand ? false : true"
                :loading="loading"
              >
                <el-option
                  v-for="model in models"
                  :key="model.ID"
                  :label="model.Model"
                  :value="model.ID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品标题" prop="PdtName">
              <el-input
                class="input-size"
                v-model="pdtinfo.PdtName"
                autocomplete="off"
                placeholder="请输入商品标题"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品编码" prop="PdtCode">
              <el-radio-group v-model="pdtinfo.ShowType">
                <el-radio label="1">仅显示套餐标题</el-radio>
                <el-radio label="2">显示套餐标题+参数</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="成色标签" prop="Quality">
              <el-tag
                :key="tag"
                v-for="tag in pdtinfo.QualityTags"
                :disable-transitions="false"
                :closable="true"
                @close="handleQualityTagClose(tag)"
              >
                {{ tag }}
              </el-tag>
  
              <el-input
                class="input-new-val"
                v-if="inputQVisible"
                v-model="QualityVal"
                ref="saveQualityTagInput"
                @keyup.enter="handleQInputConfirm"
                @blur="handleQInputConfirm"
                size="small"
              >
              </el-input>
  
              <el-button
                v-if="inputshow"
                class="button-new-val"
                @click="showQInput"
                size="small"
                :disabled="disabled"
                >+ 添加</el-button
              >
            </el-form-item>
            <el-form-item label="商品标签" prop="PdtTag">
              <el-tag
                :key="tag"
                v-for="tag in pdtinfo.PdtTags"
                :disable-transitions="false"
                :closable="true"
                @close="handlePdtTagClose(tag)"
              >
                {{ tag }}
              </el-tag>
  
              <el-input
                class="input-new-val"
                v-if="inputPVisible"
                v-model="PdtVal"
                ref="savePdtTagInput"
                @keyup.enter="handlePInputConfirm"
                @blur="handlePInputConfirm"
                size="small"
              >
              </el-input>
  
              <el-button
                v-else
                class="button-new-val"
                @click="showPInput"
                size="small"
                :disabled="disabled"
                >+ 添加</el-button
              >
            </el-form-item>
  
            <!--<el-form-item label="一级行业" prop="MainClass">
              <el-select
                v-model="pdtinfo.MainClass"
                placeholder="请选择"
                @change="getIndustry"
              >
                <el-option
                  v-for="item in MainClass"
                  :key="item.IndustryCode"
                  :label="item.IndustryName"
                  :value="item.ID"
                >
                  <span class="sel_cate_name">{{ item.IndustryName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
  
            <el-form-item label="二级行业" prop="SubClass">
              <el-select v-model="pdtinfo.SubClass" placeholder="请选择">
                <el-option
                  v-for="item in SubClass"
                  :key="item.IndustryCode"
                  :label="item.IndustryName"
                  :value="item.ID"
                >
                  <span class="sel_cate_name">{{ item.IndustryName }}</span>
                </el-option>
              </el-select>
            </el-form-item>-->
  
            <el-form-item label="商品轮播图" prop="PdtImg">
              <div style="display: flex; flex-direction: row">
                <draggable
                  class="vue-draggable"
                  v-model="pdtinfo.PdtImg"
                  @start="onDragStart"
                  @end="onDragEnd"
                  item-key="index"
                >
                  <template #item="{ element, index }">
                    <div class="draggable-item">
                      <el-image
                        :src="element.url"
                        :preview-src-list="[element.url]"
                      ></el-image>
                      <div class="shadow" @click="onRemoveHandler(index)">
                        <i class="el-icon-delete"></i>
                      </div>
                    </div>
                  </template>
                </draggable>
                <el-upload
                  class="avatar-uploader"
                  :action="upload_url"
                  list-type="picture-card"
                  :on-success="UploadSuccess"
                  :on-change="UploadChange"
                  :on-error="UploadError"
                  :on-remove="RemovePic"
                  :on-preview="Preview"
                  accept=".jpg,.jpeg,.png"
                  :show-file-list="false"
                  multiple
                  :file-list="FileList"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <!--
                          <template #tip>
                              <div class="el-upload__tip">只能上传 jpg/png 文件</div>
                          </template>-->
              </div>
              <el-dialog v-model="dialogVisible">
                <img style="width: 100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="商品详情" prop="PdtDesc">
              <EditorBar
                :value="pdtinfo.PdtDesc"
                :isClear="isClear"
                @change="DescChange"
              ></EditorBar>
            </el-form-item>
            <!-- <el-form-item label="是否调用模板" prop="IsShowBak">
              <el-switch
                v-model="pdtinfo.UseTemplate"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item> -->
            <el-form-item label="选择模板" v-if="pdtinfo.UseTemplate">
              <el-select v-model="pdtinfo.TempIDs" placeholder="请选择" multiple>
                <el-option
                  v-for="temp in temps"
                  :key="temp.ID"
                  :value="temp.ID"
                  :label="temp.Title"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="配送方式" prop="DeliverType">
              <el-select
                v-model="pdtinfo.Delivertype"
                placeholder="请选择"
                multiple
              >
                <el-option value="1" label="寄出到付"></el-option>
                <el-option value="2" label="寄出包邮"></el-option>
                <el-option value="3" label="归还自付"></el-option>
                <el-option value="4" label="归还到付"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="同步商品到代理" v-if="ismerchant != 1" prop="Syncmerchant">
              <el-select
                v-model="pdtinfo.Syncmerchant"
                placeholder="请选择"
                multiple
              >
                <el-option 
                v-for="item in sync" 
                :key="item.ID"
                :label="item.Merchant"
                :value="item.ID"
                >
                  
                </el-option>
                
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否上链" prop="IsShowBak">
              <el-switch
                v-model="pdtinfo.OnChain"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
            <el-form-item label="是否投保" prop="IsShowBak">
              <el-switch
                v-model="pdtinfo.Insurance"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item> -->
  
            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('pdtinfo')"
                :disabled="excuting ? true : false"
                >保存</el-button
              >
              <el-button @click="back">返回</el-button>
            </el-form-item>
          </el-form>
        </div>
        </el-scrollbar>
      </el-card>
    </div>
  </template>
  
  <style lang="less" scoped>
  .vue-draggable {
    display: flex;
    flex-wrap: wrap;
  
    .draggable-item {
      width: 148px;
      height: 148px;
      margin-right: 5px;
      margin-bottom: 5px;
      border: 1px solid #ddd;
      border-radius: 6px;
      position: relative;
      overflow: hidden;
  
      .el-image {
        width: 100%;
        height: 100%;
      }
      .shadow {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s;
        color: #fff;
        font-size: 20px;
        line-height: 20px;
        padding: 2px;
        cursor: pointer;
        border-bottom-left-radius: 6px;
      }
      &:hover {
        .shadow {
          opacity: 1;
        }
      }
    }
    &.hideShadow {
      .shadow {
        display: none;
      }
    }
  
    &.disabled {
      position: relative;
  
      .disabledShadow {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.4);
  
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  </style>
  <style scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .option {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .pdtform >>> .input-size {
    width: 40em;
  }
  .pdtform >>> .avatar-uploader-icon {
    font-size: 40px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  .pdtform >>> .avatar {
    width: 178px;
    display: block;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-val {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-val {
    width: 90px;
    margin-left: 10px;
    vertical-align: middle;
  }
  </style>
  <script>
  import Cookies from "js-cookie";
  import qs from "qs";
  import constant from "@/constant";
  import EditorBar from "@/components/WangEditor";
  import { ElMessage } from "element-plus";
  import draggable from "vuedraggable";
  export default {
    data() {
      return {
        excuting: false,
        merchants: [],
        drag: false,
        inputshow:true,
        retparams: {
          keyword: "",
          categoryid: "",
          brandid: "",
          modelid: "",
          status: "",
          curpage: "",
          pagesize: "",
        },
        loading: false,
        nocatesel: true,
        nobrandsel: true,
        dialogImageUrl: "",
        dialogVisible: false,
        option: [],
        upload_url: constant.upload_temp_img_url2,
        FileList: [],
        inputQVisible: false,
        inputPVisible: false,
        PdtVal: "",
        QualityVal: "",
        categorys: [],
        brands: [],
        models: [],
        temps: [],
        pdtinfo: {
          ID: "",
          Syncmerchant:[],
          merchantid: "",
          PdtDesc: "",
          PdtThumb: "",
          PdtName: "",
          PdtCode: "",
          OnChain: false,
          Insurance: false,
          UseTemplate: false,
          category: "",
          brand: "",
          model: "",
          PdtTags: [],
          QualityTags: [],
          Delivertype: [],
          PdtImg: [],
          TempIDs: [],
          ShowType: "2",
          MainClass: "",
          SubClass: "",
        },
        sync:[],
        props: {
          label: "label",
          value: "value",
          disabled: "Disabled",
        },
        MainClass: [],
        SubClass: [],
        rules: {
          PdtName: [
            {
              required: true,
              message: "请输入商品标题",
              trigger: "blur",
            },
          ],
          PdtDesc: [
            {
              required: true,
              message: "请填写商品详情图",
              trigger: "blur",
            },
          ],
  
          PdtImg: [
            {
              required: true,
              message: "请上传商品轮播图",
              trigger: "blur",
            },
          ],
          Delivertype: [
            {
              required: true,
              message: "请选择配送方式",
              trigger: "blur",
            },
          ],
          category: [
            {
              required: true,
              message: "请选择品类",
              trigger: "blur",
            },
          ],
          brand: [
            {
              required: true,
              message: "请选择品牌",
              trigger: "blur",
            },
          ],
          model: [
            {
              required: true,
              message: "请选择型号",
              trigger: "blur",
            },
          ],
          /*MainClass: [
            {
              required: true,
              message: "请选择一级行业",
              trigger: "blur",
            },
          ],
          SubClass: [
            {
              required: true,
              message: "请选择二级行业",
              trigger: "blur",
            },
          ],*/
        },
      };
    },
    components: {
      EditorBar,
      draggable,
    },
    methods: {
      GetTemp() {
        this.axios
          .get(constant.getpdttemp, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              status: 1,
              merchantid: this.pdtinfo.merchantid,
            },
          })
          .then((response) => {
            console.log("temp");
            console.log(response.data);
            this.temps = response.data;
          });
      },
      onDragStart() {
        this.drag = true;
      },
      onDragEnd() {
        this.drag = false;
        console.log(this.pdtinfo.PdtImg);
      },
      onRemoveHandler(index) {
        this.pdtinfo.PdtImg.splice(index, 1);
      },
      GetModel() {
        this.loading = true;
        this.axios
          .get(constant.get_plan_model_url, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              category: JSON.stringify(this.pdtinfo.category),
              brand: this.pdtinfo.brand,
              status: 1,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.models = response.data;
            this.loading = false;
          });
      },
      SetModel() {
        this.pdtinfo.model = "";
      },
      ResetInfo() {
        (this.pdtinfo.brand = ""), (this.pdtinfo.model = "");
      },
  
      handleQualityTagClose(tag) {
        this.inputshow = true
        this.pdtinfo.QualityTags.splice(this.pdtinfo.QualityTags.indexOf(tag), 1);
      },
      handlePdtTagClose(tag) {
        this.pdtinfo.PdtTags.splice(this.pdtinfo.PdtTags.indexOf(tag), 1);
      },
      showPInput() {
        this.inputPVisible = true;
        this.$nextTick(() => {
          this.$refs.savePdtTagInput.$refs.input.focus();
        });
      },
      handlePInputConfirm() {
        let PdtVal = this.PdtVal;
        console.log(PdtVal)
        if (PdtVal) {
          console.log(this.pdtinfo.PdtTags)
          let cur_obj = this.pdtinfo.PdtTags;
          console.log(cur_obj)
          cur_obj.push(PdtVal);
        }
        this.inputPVisible = false;
        this.PdtVal = "";
      },
      showQInput() {
        this.inputQVisible = true;
        this.$nextTick(() => {
          this.$refs.saveQualityTagInput.$refs.input.focus();
        });
      },
      handleQInputConfirm() {
        let QualityVal = this.QualityVal;
        console.log(QualityVal)
        if (QualityVal) {
          let cur_obj1 = this.pdtinfo.QualityTags
          cur_obj1.push(QualityVal);
          this.inputshow = false
        }else{
          this.inputshow = true
          this.inputQVisible = false;
        }
        this.inputQVisible = false;
        this.QualityVal = "";
      },
  
      UploadSuccess(response, file) {
        console.log(response);
        console.log(file);
        if (response.error == "0") {
          var uid = file.uid;
          var name = file.name;
          var url = "";
          if (file.response && file.response == 0) url = file.response.url;
          else url = response.url;
          var obj = new Object();
          obj.uid = uid;
          obj.name = name;
          obj.url = url;
          this.pdtinfo.PdtImg.push(obj);
        }
        console.log(this.pdtinfo.PdtImg);
      },
  
      submitForm(formName) {
        let that = this;
        this.excuting = true;      
        this.$refs[formName].validate((valid) => {
          if (valid) {
            for (let i = 0; i < this.MainClass.length; i++) {
              if (this.pdtinfo.MainClass == this.MainClass[i].ID) {
                this.pdtinfo.MainClass = this.MainClass[i].IndustryCode;
              }
            }
  
            for (let i = 0; i < this.SubClass.length; i++) {
              if (this.pdtinfo.SubClass == this.SubClass[i].ID) {
                this.pdtinfo.SubClass = this.SubClass[i].IndustryCode;
              }
            }
            console.log(that.pdtinfo);
            this.axios
              .post(constant.save_pdt_url, qs.stringify(this.pdtinfo), {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                params: {
                  ismerchant: this.ismerchant,
                  mid: this.mid,
                },
              })
              .then((response) => {
                console.log(response.data);
                const cur_obj = this;
                if (response.data == "OK") {
                  ElMessage.success({
                    type: "success",
                    message: "保存成功",
                    duration: 1000,
                    onClose: function () {
                      that.excuting = false;
                      cur_obj.$router.push({ path: "/pdt" });
                    },
                  });
                }else if(response.data == "LOCK"){
                  this.$message.error("该产品锁定中，请联系运维解锁再进行操作");
                  return false;
                }
              });
          } else {
            return false;
          }
        });
      },
      DescChange(val) {
        this.pdtinfo.PdtDesc = val;
      },
      RemovePic(file, filelist) {
        console.log(file);
        console.log(filelist);
        this.pdtinfo.PdtImg = [];
        for (var i = 0; i < filelist.length; i++) {
          var uid = filelist[i]["uid"];
          var name = filelist[i]["name"];
          var url = filelist[i]["url"];
          var obj = new Object();
          obj.uid = uid;
          obj.name = name;
          obj.url = url;
          this.pdtinfo.PdtImg.push(obj);
        }
      },
      Preview(file) {
        console.log(file);
        if (file.response) {
          if (file.response.error == 0) {
            this.dialogImageUrl = file.response.url;
            console.log(this.dialogImageUrl);
          }
        } else {
          this.dialogImageUrl = file.url;
        }
  
        this.dialogVisible = true;
      },
      back() {
        this.$router.go(-1);
      },
  
      getIndustry(parentid) {
        this.loading = true;
        this.axios
          .get(constant.industry, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              parentid,
            },
          })
          .then((response) => {
            console.log(response.data);
            if (parentid > 0) {
              this.SubClass = response.data;
            } else {
              this.MainClass = response.data;
            }
            this.loading = false;
          });
      },
    },
    created: function () {
      console.log(this.QualityTags);
      this.ismerchant = Cookies.get("IsMerchant");
      this.mid = Cookies.get("mid");
      console.log("mid--" + Cookies.get("mid"));
      if (Cookies.get("mid") != "undefined")
        this.pdtinfo.merchantid = Cookies.get("mid");
      var id = this.$route.query.id;
      var keyword = this.$route.query.keyword;
      if (keyword) this.retparams.keyword = keyword;
      var categoryid = this.$route.query.categoryid;
      if (categoryid) this.retparams.categoryid = categoryid;
      var brandid = this.$route.query.brandid;
      if (brandid) this.retparams.brandid = brandid;
      var modelid = this.$route.query.modelid;
      if (modelid) this.retparams.modelid = modelid;
      var status = this.$route.query.status;
      if (status) this.retparams.status = status;
      var curpage = this.$route.query.curpage;
      if (curpage) this.retparams.curpage = curpage;
      var pagesize = this.$route.query.pagesize;
      if (pagesize) this.retparams.pagesize = pagesize;
      console.log(this.retparams);
      console.log(id);
      this.axios
        .get(constant.get_brand_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            status: 1,
          },
        })
        .then((response) => {
          this.brands = response.data;
        });
      this.axios
        .get(constant.getpdttemp, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            status: 1,
            merchantid: this.pdtinfo.merchantid,
          },
        })
        .then((response) => {
          console.log("temp");
          console.log(response.data);
          this.temps = response.data;
        });
  
      this.axios
        .get(constant.get_cascader_url, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.option = response.data;
        });
      this.axios.get(constant.syncagent).then((response)=>{
          console.log(response.data);
          this.sync = response.data.data;
      })
      this.axios
        .get(constant.allmerchant, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.merchants = response.data;
        });
      if (id) {
        this.axios
          .get(constant.get_sel_pdt_url, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              id: id,
            },
          })
          .then((response) => {
            console.log(response.data);
  
            this.pdtinfo = response.data.pdtinfo;
  
            this.models = response.data.modellist;
            this.temps = response.data.temps;
            this.FileList = response.data.pdtinfo.ImgList;
            if (response.data.pdtinfo["Quality"] == "" || response.data.pdtinfo["Quality"] == null)
              this.pdtinfo.QualityTags = [];
            if (response.data.pdtinfo["Tag"] == "" || response.data.pdtinfo["Tag"] == null) 
              this.pdtinfo.PdtTags = [];
            console.log("产品信息");
            console.log(this.pdtinfo);
            if(this.pdtinfo.QualityTags.length > 0){
              this.inputshow = false
            }
            console.log(this.pdtinfo.PdtImg);
            this.axios
              .get(constant.getpdttemp, {
                headers: {
                  "Content-Type": "application/json",
                },
                params: {
                  status: 1,
                  merchantid: this.pdtinfo.merchantid,
                },
              })
              .then((response) => {
                console.log("temp");
                console.log(response.data);
                this.temps = response.data;
              });
          });
      }
  
      // 读取行业一级分类
      this.getIndustry(0);
    },
  };
  </script>